.ScrollCards{
    /* overflow-y: scroll; */
    overflow: auto;
    text-align: center;
    height: 100%;
}

.ExpandContract{
    transition: all 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
    display: inline-block;
}