.CreateSetHeader{
    margin-bottom: 20px;
    color: black;
}
.contactPopup{
    padding: 4%;
    height: 100%;
    color: black;
}
.col-md-3{
    background: #ff9b00;
    padding: 4%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.contact-info{
    margin-top:10%;
}
.contact-info img{
    margin-bottom: 15%;
}
.contact-info h2{
    margin-bottom: 10%;
}
.col-md-9{
    background: #fff;
    padding: 3%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.contact-form label{
    font-weight:600;
}
.contact-form .submitButton{
    background: #25274d;
    color: #fff;
    font-weight: 600;
}
.contact-form .submitButton:focus{
    box-shadow:none;
}
.contact-form{
    height: 100%;
}

.cardList{
    height: 50%;
    margin-top: 10px;
}

.cardList textarea{
    height: 90%;
}

.submit{
    text-align: center;
    margin-top: 10px;
}

.CreateCustomSetTitle{
    font-size: 30px;
    text-align: center;
    padding-bottom: 20px;
    color: black;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: "Nunito", sans-serif;
}

.form-control-white, .form-control-white:focus{
    background-color: white;
    color: black;
}

.dark-themed-textarea{
    background-color: #000000cc !important;
    color: white;
}
.dark-themed-textarea:focus{
    color: white !important;
}