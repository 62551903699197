.footerContainer{
  width: 75%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="footerContainer"] {
      width: 100%
  }
}
