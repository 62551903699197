.setGrid{
    width: 100%;
    height: 80%;
}

.edit-grid-button{
    background: #238b1c !important;
    height: 38px;
    line-height: 38px;
}

.edit-grid-button:hover{
    background: #3e8639 !important;
}

.delete-grid-button{
    background: #c92121 !important;
    height: 38px;
    line-height: 38px;
}

.delete-grid-button:hover{
    background: #942e2e !important;
}

.action-button{
    margin-left: 5px;
    margin-right: 5px;
}