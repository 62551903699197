.dark-themed-textarea{
    background-color: #000000cc !important;
    color: white;
}
.dark-themed-textarea:focus{
    color: white !important;
}
.forgotPassword{
    cursor: pointer;
}
.forgotPassword:hover{
    color: rgb(201, 201, 201);
}