.AddBoosterButton{
    margin-bottom: 15px;
}

.DeleteButton.btn-danger{
    cursor: pointer;
    margin-right: 5px;
    font-size: .8rem;
    height: 50px;
    line-height: 40px;
}

.SetBuilderTitle{
    display: block;
    padding: 0.5rem 2rem;
    color: rgb(227, 255, 66);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.setBuilderWrapper{
    padding-left: 50px;
    padding-right: 50px;
    height: 120%;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="setBuilderWrapper"] {
        padding-left: 5px;
        padding-right: 5px;
    }
  }

.overflowSetBuilderWrapper{
    overflow-y: auto;
    height: 100%;
}
.overflowSetBuilderWrapper::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.add-card-button{
    margin-right: 10px;
}